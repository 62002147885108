body {
    margin: 0;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
}

.login-form {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
}

.col {
    display: flex;
    flex-direction: column;
    padding: 1em;
}

.row {
    display: flex;
    justify-content: center;
}

.area {
    height: 200px;
    width: 300px;
}

.logout h3 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

input[type="checkbox" i] {
    margin: 0;
    margin-bottom: 10px !important;
}